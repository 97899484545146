/* Basic CSS */

.btn-default {
  background-color: #EA690B;
  border-color: #EA690B;
  color: #ffffff;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #F57C26;
  /* border-color: #F57C26; */
  color: #ffffff
}

/* .btn-outline-default:hover {
  border-color: #EA690B !important;
} */

.btn.btn-default i,
.btn.btn-default .svg-icon {
  color: #ffffff;
}

/* .btn-outline-default:hover {
  border-color: #198754 !important;
} */

.bg-default {
  background-color: #F57C26;
}

.btn-outline-default.active {
  /* background-color: #198754;
  border-color: #198754; */
  background-color: #e8fff3;
  border-color: #e8fff3;
  /* background-color: #50cd89;
  border-color: #50cd89; */
  /* color: #ffffff; */
}

.text-default {
  color: #F57C26;
}

.btn.btn-outline-success.active {
  border-color: #47be7d !important;
}

.location-checkbox .form-check-input {
  border-color: #5E6278;
}

.location-checkbox .form-check-input:checked {
  border-color: #50cd89 !important;
}

.badge-default {
  background-color: #F57C26;
  border-color: #F57C26;
  color: #fff;
}

.btn-outline-default.active .svg-icon {
  color: #ffffff;
}

.ant-select-selector {
  height: 38px !important;
  width: 100% !important;
  border: 1px solid #E4E6EF !important;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Poppins, Helvetica, "sans-serif";
}

.ant-select-show-search {
  width: 100% !important;
}

.ant-select .ant-select-selection-placeholder {
  color: #a1a5bc;
  font-size: 1.1rem;
  font-weight: 500;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 40px;
  transition: all 0.3s;
  font-size: 1.2rem;
  font-weight: 500;
}

.ant-select-single .ant-select-selector {
  color: #5E6278;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 30px;
  font-family: Poppins, Helvetica, "sans-serif";
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 3px 0;
}

@media (min-width: 992px) {
  .header-fixed.toolbar-fixed .wrapper {
    padding-top: calc(0px + var(--kt-toolbar-height));
  }
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  /* image-rendering: "/metronic8/react/demo1/media/avatars/Locator.png"; */
  font-size: 2rem;
}

.pin-text {
  font-size: 1.3em;
}

.pin-icon-current {
  font-size: 2vw;
  color: red;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 2vw;
  }

  .pin-icon-current {
    font-size: 2vw;
    color: red;
  }
}

/* .leaflet-container {
    width: 100%;
    height: 100vh;
  } */
/* .map-container {
    height: 400px;
    width: 600px;
} */
.leaflet-container {
  height: 380px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}


.map-list-number {
  top: -11px;
  left: 0;
  right: 3px;
  display: block;
  text-align: center;
}

.pushpin-popup {
  display: none;
}

.pushpin:hover .pushpin-popup {
  display: block;
}

.labelCanvas {
  width: 50px;
  height: 50px;
}

.map-marker-circle {
  position: absolute;
  top: 0;
  left: 0px;
  width: 32px;
  height: 20px;
  border-radius: 50px;
  text-align: center;
  margin: 3px 0 0 0;
  display: block;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
}

.map-pin-icon {
  width: 32px;
  height: 32px;
  display: block;
}

.map-thumbnail {
  width: auto;
  height: 30px;
  border-radius: 15px;
}

.map-thumbnail-multiple{
  width: auto ;
  height: 38px;
  border-radius: 50px;
}

.map-thumbnail-multiple-parent {
  width: 100%;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.map-pin {
  margin-left: -10px;
}


.form-control-sm-secondary {
  font-size: 1.1rem;
  min-height: 38px;
  padding: 0.6rem 0.675rem 0.5rem 0.775rem
}

.map-marker-circle .map-thumbnail {
  height: 20px;
}

/* dynamic Theme */
:root{
  --dynamic-color: #009ef7;
  --dynamic-icon-color: #c9c632;
  --dynamic-height : 590px;
  --dynamic-height-space :  77px;
  }

  .dynamic-theme .location-marker{
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -8px;
    border-radius: 50% 50% 50% 0;
    border: 2px solid var(--dynamic-icon-color);
    width: 42px;
    height: 42px;
    transform: rotate(-45deg);
    background-color:#fff;
  }
  .dynamic-theme .single-location-marker{
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -8px;
    border-radius: 50% 50% 50% 0;
    border: 2px solid var(--dynamic-icon-color);
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
    background-color:#fff;
  }

  .dynamic-theme .single-location-marker-with-just-number{
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -8px;
    border-radius: 50% 50% 50% 0;
    border: 2px solid var(--dynamic-icon-color);
    background:  var(--dynamic-icon-color);
    width: 32px;
    height: 32px;
    transform: rotate(-45deg);
  }

.dynamic-theme .text-primary {
  color: var(--dynamic-color) !important;
}

.dynamic-theme .btn-primary {
  background-color: var(--dynamic-color) !important;
}

.dynamic-theme .btn-primary:hover,
.dynamic-theme .btn-primary:focus,
.dynamic-theme .btn.btn-primary:focus:not(.btn-active),
.dynamic-theme .btn.btn-primary:hover:not(.btn-active),
.dynamic-theme .btn.btn-primary:active:not(.btn-active) {
  background-color: var(--dynamic-color) !important;
  border-color: var(--dynamic-color) !important;
}

.dynamic-theme .btn.btn-outline.btn-outline-dashed:hover:not(.btn-active) {
  border-color: var(--dynamic-color) !important;
}

.dynamic-theme .bg-primary {
  background-color: var(--dynamic-color) !important;
}

.dynamic-theme .border-primary {
  border-color: var(--dynamic-color) !important;
}

.dynamic-theme .bg-light-primary {
  background-color: #d5f0ff !important;
}

.leaflet-tooltip {
  pointer-events: none;
}

.leaflet-tooltip * {
  pointer-events: auto;
}
.custom-leaflet-tooltip-start-jorney{
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  right: -25px;
  bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}


.custom-leaflet-tooltip-end-jorney{
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  right: -78px;
  bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  width: 300px;
}
.custom-mt-2 {
  margin-top: 2px;
}

.close-button{
  float: right;
}

.custom-tooltip-for-multiple-location-bingmap{
  position: absolute !important;
  top: -40px;
  left: 24px;
  width: 300px;
  opacity: 1 !important;
}

.custom-tooltip-for-multiple-location-streetmap{
  position: absolute !important;
  left: 28px;
  opacity: 1 !important;
  padding: 0 !important;
  border-radius: 0.625rem !important;
}

.custom-tooltip-for-multiple-location-googlemap {
  width: 300px;
  position: absolute !important;
  left: 32px;
  opacity: 1 !important;
}
.form-check-custom{ font-size: 12px;}
.line-height-normal{ line-height: normal !important;}
.close-tooltip-icon{
  position: absolute;
  top: -30px;
  right: 0;
}


.endJourney-tooltip{
  right: 578px;
  bottom: 52px;
}

.startJourney-tooltip{
  bottom: 38px;
  left: -19px;
}

.language-scroll{
  overflow: auto;
  height: 300px;
  white-space: nowrap;
  scroll-behavior: smooth;
}


 .Avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 10px;
 }

.CardHeader {
font-weight: 500;
text-align: start;
}

.Author {
display: flex;
align-items: center;
}

.CardFooter {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
.characters{
padding-left: 0;
padding: 15px;
background-color: #f2f2f2;
}
.DragItem {
padding: 5px 10px;
border-radius: 6px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
background: white;
margin: 0 0 8px 0;
display: grid;
grid-gap: 20px;
flex-direction: column;
}

.background {
  height: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.title {
  color: #ffffff;
  font-family: verdana;
  text-align: center;
  margin: 0;
  padding: 30px 0;
  opacity: .9;
}

.color-selector {
  display: block;
  /* margin: 0 auto; */
  border-radius: 0.475rem;
  position: relative;
  padding: 6px 32px 6px 10px;
  font-family: verdana;
  background: white;
  width: 250px;
   border: 1px solid #E4E6EF;
}
 
  
  .circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 10px;
  }
  
  .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  
  .custom-span {
    display: inline-block;
    vertical-align: middle;
  }


.overlay {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 105px;
  transition: 1s;
  
  &.active {
    top: calc(-50% + 105px);
    width: 160%;
    height: 160%;
    left: -30%;
  }
}

.w-80{
  width: 80%;
}

.top-location{ width: 200px;min-width: 200px;}

.routing-machine-location-img{
   height: 20px;
   width: 100%;
}
.tooltip-location-icon{
  height: 20px;
   width: auto;
}
.leaflet-tooltip{ min-width: 225px;}

.text-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.custom-remove-logo-button{
  background-color: #f1416c;
}

.custom-margin-left{
  margin-left: 6px;
}
.custom-min-height{
   min-height: 20px;
}

.custom-image{
  height: 45px;
  width: auto;
}
.time-box{
  position: absolute;
  left: 15px;
  background-color: #fff;
  bottom: -75px;
  border: solid 1px #ccc !important;
  min-width: 300px;
  height: 180px;
  z-index: 9;
}

 .time-box-table tr td{
   text-align: center;
   font-size: 11px;
}

.time-box-table tr th{
  font-size: 11px;
} 

.map-time-box-table tr td{
  text-align: center !important;
  font-size: 12px !important;

}

.map-time-box-table tr th{
  font-size: 12px !important;
 }

 .street-map-time-box-table tr td{
  text-align: center !important;
  font-size: 12px !important;
}

.street-map-time-box-table tr th{
  font-size: 12px !important;
 }

 .bing-map-time-box-table tr td{
  text-align: center !important;
  font-size: 12px !important;
}

.bing-map-time-box-table tr th{
  font-size: 12px !important;
 }

 .google-map-time-box-table tr td{
  text-align: center !important;
  font-size: 12px !important;
}

.google-map-time-box-table tr th{
  font-size: 12px !important;
 }



 .routing-street-map{
   width: 300px;
 }


 .google-map{
  width: 300px;
}

 .vertical-list-time-box-table tr td{
  text-align: center !important;
  font-size: 12px !important;
}

.vertical-list-time-box-table tr th{
  font-size: 12px !important;
 }


 .horizontal-list-time-box-table tr td{
  text-align: center !important;
  font-size: 11px !important;
  padding: 5px;
}

.horizontal-list-time-box-table tr th{
  font-size: 11px !important;
  padding: 5px;
 }


.time-box-table .map-time-box-tabletr:nth-child(even) {background-color: #f2f2f2;}

/* #scrollContainerRight:hover{
  overflow: visible !important;
  z-index: 1;
} */

.scrollContainerRight{
  overflow: hidden !important;
  z-index: 1;
}

.inner:hover {
  position: absolute;
}
.fa-lg {
  font-size: 1.25em !important;
}

.cursor-pointer{
   cursor: pointer !important;
}


.demo{
   height: 1500px;
}

 .location-counter{
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Makes the element a circle */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dynamic-color) !important;
  /* padding: 5px 8px 4px 8px; */
  /* display: inline; */
  }

  .location-counter-map{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-left: -9px;
    margin-top: 4px;
    font-weight: 600;
    font-size: 11px;
  }
  .location-counter-bing-map{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-left: 0px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 11px;
  }
  .btn i{
    line-height: 1rem;
  }
.master-content{
  padding: 15px 0px;
}






  /* cropper css */

      
button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

body{
  font-family: 'Roboto', sans-serif;
}

.ci-user-picture {
    min-width: 150px;
    margin-right: 16px;
}
.ci-user-picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.btn-default {
    display: inline-block;
    padding: 14px 32px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    transition: 0.5s;
    text-align: center;
    text-transform: capitalize;
}
.bg-blue {
    background-color: #006AFA;
    color: #fff !important;
}
.filepreviewprofile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.modal-header-bg {
    width: 100%;
    height: 12px;
    background: linear-gradient(269.44deg, #006AFA 2.3%, #00AE86 99.19%);
}
.cropImageModal .modal-dialog{
    max-width: 750px;
    width: 90%;
    margin: 0.5rem auto;
}
.up-photo-title{
    padding: 8px 15px;
    text-align: center;
}

#upload-demo{
    position: relative;
	width: 100%;
	height: 250px;
  padding-bottom:0;
}
.croppie-container h5 {
    position: absolute;
    bottom: 4px;
    text-align: center;
    left: 0;
    right: 0;
    color: #fff;
    z-index: 2;
    font-size: 15px;
}
figure figcaption {
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    padding-left: 9px;
    padding-bottom: 5px;
    text-shadow: 0 0 10px #000;
}
.croppie-container .cr-image{
    right: 0;
}
.croppie-container .cr-slider-wrap {
    width: 275px;
}
.cr-slider-wrap p{
    font-size: 13px;
    color: #8D8D94;
    text-align: left;
}
.cr-slider{
    width: 275px;
}
.upload-action-btn {
    margin-top: 60px;
    padding-top: 40px;
}
.cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
  
.cr-slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.cr-slider::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #006AFA;
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.cr-slider::-webkit-slider-runnable-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
}
.cr-slider:focus::-webkit-slider-runnable-track {
    background: #E0E0E0;
}
.cr-slider::-moz-range-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
}
.cr-slider::-ms-track {
    width: 275px;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #E0E0E0;
    border-radius: 3px;
    border:none;
    color: transparent;
}

.custom-service-edit-btn{
   width: 30px;
   height: 30px;
}

.search-bar-padding{
   padding:1rem 0.25rem !important;
}

.map-container-padding{
  padding: 0rem 1rem !important;
}
.map-container-header-padding{
  padding: 0 0.25rem !important;
}

.iframe-address-section{
  height: calc(var(--dynamic-height) - var(--dynamic-height-space));
  overflow: auto;
}

.address-section {
  /* max-height: 450px; */
  /* height: calc(var(--dynamic-height) - 147px); */
  /* height: calc(var(--dynamic-height) - 77px); */
  height: calc(var(--dynamic-height) - var(--dynamic-height-space));

  
  overflow: auto;
}

.map-container{
  /* min-height: 150px; */
  height: calc(var(--dynamic-height) -  var(--dynamic-height-space)) !important; 
  width: 100%;
}


@media only screen and (min-width: 1400px) {
  .table.table-lg > :not(caption) > * > * {
    padding: 1.75rem 0.75rem;
  }  
}
/* @media only screen and (min-width: 1366px) and (max-width:1600px) {
  .table.table-lg > :not(caption) > * > * {
    padding: 1.18rem 0.75rem;
  }  
} */

@media only screen and (min-width: 992px) and (max-width:1024px) {
  .table.table-lg > :not(caption) > * > * {
    padding: 1.18rem 0.75rem;
  }  
}


@media only screen and (max-width: 767px) {

  .horizontal-list-time-box-table tr td{
    text-align: center !important;
    font-size: 10px !important;
    padding: 5px;
  }
  
  .horizontal-list-time-box-table tr th{
    font-size: 10px !important;
    padding: 5px;
   }
    
}

@media only screen and (min-width: 576px) and (max-width:767px) {
  .order-md-1{
    order: 1 !important;
  }
  .order-md-2{
    order: 2 !important;
  }
  .order-md-3{
    order: 3 !important;
  }
}